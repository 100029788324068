import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Skyline from "../components/skyline"
import { Link } from "gatsby";

const SuccessPage = () => (
    <Layout>
        <SEO title="Success page"/>
        <div id="bannerDiv">
            <Skyline className="skyline-pic"/>
            <div className="imageText">
                <h1>Thanks for emailing us!</h1>
            </div>
        </div>
        <div className="success">
            <p> We will get back to you as soon as possible. Please give us 2-3 days to respond.</p>
            <p>Return to <Link to="/" className="pinkHover">homepage</Link></p>
        </div>
    </Layout>
)

export default SuccessPage